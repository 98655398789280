import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Label } from '../components/Label'
import styletron from 'gatsby-plugin-styletron'
import { Palette, Spacing } from '../theme'

const headerImage = require('../images/unsplash.jpg')
const patternImage = require('../images/02.png')

const IndexPage = () => {
    const css = styletron().css
    return (
        <Layout>
            <SEO title='Home' keywords={[`Bradley Sipp`, `Attorney`, `Family Law`]} />
            <div className={css(headerContainerRule)}>
                <img
                    src={headerImage}
                    className={css({
                        zIndex: 1,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'block',
                        backgroundColor: 'black',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'bottom center',
                    })}
                />
                <div
                    className={css({
                        width: '500px',
                        height: '300px',
                        zIndex: 3,
                        position: 'relative',
                        padding: Spacing.base,
                        '@media screen and (max-width: 680px)': {
                            marginTop: Spacing.xlarge,
                            paddingTop: Spacing.base,
                        },
                    })}>
                    <Label
                        variant='title1'
                        className={css({
                            color: Palette.white,
                        })}>
                        Welcome to the Law Offices of Bradley A. Sipp
                    </Label>
                </div>
            </div>

            <div
                className={css({
                    maxWidth: '600px',
                    width: '100%',
                    paddingLeft: Spacing.small,
                    paddingRight: Spacing.small,
                    marginTop: Spacing.base,
                    marginBottom: Spacing.base,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                })}>
                <Label variant='title2'>Family and Criminal Attorney</Label>
                <Label className={css({ marginTop: Spacing.large })}>
                    The Law Offices of Bradley A. Sipp, P.C., L.L.O. specializes in providing the highest quality legal representation in
                    family, criminal, and civil litigation matters. Our firm aggressively represents clients throughout Eastern Nebraska,
                    including Lancaster County, Gage County, Seward County, Saline County and Saunders County.
                </Label>
                <Label className={css({ marginTop: Spacing.base })}>
                    Bradley Sipp is a highly experienced litigator and negotiator who can assist you with your case, whether it be involving
                    complex property division or the intricacies of child custody litigation. At the Law Offices of Bradley A. Sipp. P.C.,
                    L.L.O., your case is never shuffled off to an inexperienced associate.
                </Label>

                {/* <Link to='/page-2/'>Go to page 2</Link> */}
            </div>
        </Layout>
    )
}

export default IndexPage

const headerContainerRule = {
    position: 'relative',
    width: '100%',
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ':before': {
        content: '""',
        zIndex: 3,
        display: 'block',

        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundImage: `url(${patternImage})`,
        opacity: 0.2,
        backgroundSize: '4px',
        backgroundAttachment: 'fixed',
    },
    ':after': {
        content: '""',
        zIndex: 2,
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'black',
        opacity: 0.3,
    },
}
